<script setup>
defineProps({
  src: {
    type: String,
    default: "https://ddkwzdwevw9u0.cloudfront.net/fallback-image.png",
  },
  alt: {
    type: String,
    default: "",
  },
  autoJpg: {
    type: Boolean,
    default: false,
  },
  equalize: {
    type: Boolean,
    default: false,
  },
  grayscale: {
    type: Boolean,
    default: false,
  },
  stretch: {
    type: Boolean,
    default: false,
  },
  stripEXIF: {
    type: Boolean,
    default: false,
  },
  stripICC: {
    type: Boolean,
    default: false,
  },
  upscale: {
    type: Boolean,
    default: false,
  },
  bucketAlias: {
    type: String,
    default: "",
  },
  bgColor: {
    type: String,
    default: "",
  },
  blur: {
    type: String,
    default: "",
  },
  fillColor: {
    type: String,
    default: "",
  },
  toFormat: {
    type: String,
    default: "",
  },
  proportion: {
    type: String,
    default: "",
  },
  qualityLevel: {
    type: [Number, String],
    default: "",
  },
  resize: {
    type: [String, { width: [Number, String], height: [Number, String] }],
    default: "",
  },
  crop: {
    type: [String, { width: [Number, String], height: [Number, String] }],
    default: "",
  },
  rgb: {
    type: [String, { r: [Number, String], g: [Number, String], b: [Number, String] }],
    default: "",
  },
  rotate: {
    type: [Number, String],
    default: "",
  },
  sharpen: {
    type: {
      amount: {
        type: [String, Number],
        required: true,
      },
      radius: {
        type: [String, Number],
        required: true,
      },
      luminanceOnly: {
        type: Boolean,
        required: false,
      },
    },
    default: () => {},
    required: false,
  },
  watermark: {
    type: {
      bucket: {
        type: String,
        required: true,
      },
      key: {
        type: String,
        required: true,
      },
      x: {
        type: [String, Number],
        required: true,
      },
      y: {
        type: [String, Number],
        required: true,
      },
      alpha: {
        type: [String, Number],
        required: false,
      },
      wRatio: {
        type: [String, Number],
        required: false,
      },
      hRatio: {
        type: [String, Number],
        required: false,
      },
    },
    required: false,
    default: () => {},
  },
  loading: {
    type: String,
    default: () => "lazy",
  },
  preload: {
    type: Boolean,
    default: () => false,
  },
  decoding: {
    type: String,
    default: () => "async",
  },
  blurHash: {
    type: Boolean,
    default: true,
  },
});

const loadPlaceholder = ref(true);

const disablePlaceholder = () => {
  loadPlaceholder.value = false;
};
</script>

<template>
  <nuxt-img
    provider="cloudfront"
    class="bg-gray-200"
    :modifiers="{
      autoJpg,
      equalize,
      grayscale,
      stretch,
      stripEXIF,
      stripICC,
      upscale,
      bucketAlias,
      bgColor,
      blur,
      fillColor,
      toFormat,
      proportion,
      qualityLevel,
      resize,
      crop,
      rgb,
      sharpen,
    }"
    :loading="loading"
    :preload="preload"
    placeholder
    :src="src"
    :alt="alt"
    :decoding="decoding"
    @load="disablePlaceholder"
    @error="disablePlaceholder"
  />
</template>
