/**  ========================================================================
 # Custom provider for nuxt-image module. @see https://image.nuxtjs.org/.
 # Provider configured for injecting THUMBOR image filters to image path.
 # Images processing provider - AWS Serverless Images Handler
 # ========================================================================
*/

/**
 * @description Image URL processing function
 * @param { string } [src] - Image url. Image must be inside the project buckets which included to CDN specified in config.
 * @param {AvailableModifiers} [modifiers] - A object with modifiers which need to be applied to provided image.
 * @returns {{ url: string }}
 */
export function getImage(src = "https://ddkwzdwevw9u0.cloudfront.net/fallback-image.png", { modifiers = {} } = {}) {
  if (src.startsWith("blob:")) {
    return { url: src };
  }

  // Separating domain and path parts from image url
  const { CDN_URL } = useRuntimeConfig().public;

  const protocolSeparatorIndex = src.indexOf("://");

  const pathSeparatorIndex = src.indexOf("/", protocolSeparatorIndex + 3); // Skip past "://"

  // Change url domain to CDN domain if provided
  const domain = CDN_URL || src.substring(0, pathSeparatorIndex);
  let path = src.substring(pathSeparatorIndex);

  /**
   * List of filters string generators.
   * @see https://thumbor.readthedocs.io/en/latest/filters.html for more info about THUMBOR filters
   * @see https://docs.aws.amazon.com/solutions/latest/serverless-image-handler/thumbor-filters.html for images handler limitations
   */
  const resolveImageModifier = {
    /**
     * @description Auto format png images to jpg
     * @see https://thumbor.readthedocs.io/en/latest/autojpg.html
     * @returns { string }
     */
    autoJpg: () => `/filters:autojpg()`,

    /**
     * @description Auto format png images to jpg
     * @param {string} color - Color name or color in hexadecimal rgb expression without the "#" character
     * @see https://thumbor.readthedocs.io/en/latest/autojpg.html
     * @returns { string }
     */
    bgColor: (color) => `/filters:background_color(${color})`,

    /**
     * @description Apply gaussian blur to image
     * @see https://thumbor.readthedocs.io/en/latest/blur.html
     * @param {Number | String} - A blur radius. 1-150
     * @returns { string }
     */
    blur: (radius) => `/filters:blur(${radius})`,

    /**
     * @description This filter returns an image sized exactly as requested independently of its ratio.
     * It will fill the missing area with the specified color. It is usually combined with the "fit-in" option.
     * @param {String} color - Color name or color in hexadecimal rgb expression without the "#" character
     * @see https://thumbor.readthedocs.io/en/latest/filling.html
     * @returns { string }
     */
    fillColor: (color) => `/filters:fill(${color})`,

    /**
     * @description Apply a convolution matrix to the image.
     * @see https://thumbor.readthedocs.io/en/latest/convolution.html
     * @param {string} options - String with matrix options, separated by comma.
     * @example "1;2;1;2;4;2;1;2;1,3,true"
     * @returns { string }
     */
    convolution: (options) => `/filters:convolution(${options})`,

    /**
     * @description Equalizes the color distribution in the image.
     * @see https://thumbor.readthedocs.io/en/latest/equalize.html
     * @returns { string }
     */
    equalize: () => `/filters:equalize()`,

    /**
     * @description Apply grayscale to the image
     * @see https://thumbor.readthedocs.io/en/latest/grayscale.html
     * @returns { string }
     */
    grayscale: () => `/filters:grayscale()`,

    /**
     * @description Specify a output format of the image.
     * @see https://thumbor.readthedocs.io/en/latest/convolution.html
     * @param {("gif" | "heic" | "heif" | "jpeg" | "png" | "raw" | "tiff" | "webp")} format
     * @returns { string }
     */
    toFormat: (format) => `/filters:format(${format})`,

    /**
     * @description Upscale the image. This only makes sense with "fit-in"
     * @see https://thumbor.readthedocs.io/en/latest/upscale.html
     * @returns { string }
     */
    upscale: () => `/filters:upscale()`,

    /**
     * @description Do not upscale the image
     * @see https://thumbor.readthedocs.io/en/latest/no_upscale.html
     * @returns { string }
     */
    noUpscale: () => `/filters:no_upscale()`,

    /**
     * @description Apply a  specified proportion to the image's height and width when cropping.
     * @see https://thumbor.readthedocs.io/en/latest/proportion.html
     * @param {string | number} percentage - The float percentage of the proportion (0.0 to 1.0).
     * @returns { string }
     */
    proportion: (percentage) => `/filters:proportion(${percentage})`,

    /**
     * @description Changes the overall quality of the JPEG and WEBP image (does nothing for PNGs or GIFs).
     * @see https://thumbor.readthedocs.io/en/latest/quality.html
     * @param {string | number} amount - The quality level (in %) that the end image will feature.
     * @returns { string }
     */
    qualityLevel: (amount) => `/filters:quality(${amount})`,

    /**
     * @description This is not a resize! Check the link bellow
     * @see https://thumbor.readthedocs.io/en/latest/usage.html#fit-in
     * @param { ({ width: string | number, height: string | number } | string ) } dimensions
     * @example { width: 200, height: 50 } - object style
     * @example "200x50" - string style
     * @returns { string }
     */
    resize: (dimensions) => {
      if (typeof dimensions === "object") {
        return `/fit-in/${dimensions.width}x${dimensions.height}`;
      }

      return `/fit-in/${dimensions}`;
    },

    /**
     * @description This is not a crop! Check the link bellow
     * @see https://thumbor.readthedocs.io/en/latest/usage.html?#manual-crop
     * @param { ({ width: string | number, height: string | number } | string ) } dimensions
     * @example { width: 200, height: 50 } - object style
     * @example "200x50" - string style
     * @returns { string }
     */
    crop: (dimensions) => {
      if (typeof dimensions === "object") {
        return `/${dimensions.width}x${dimensions.height}`;
      }

      return `/${dimensions}`;
    },

    /**
     * @description Changes the amount of color in each of the three channels.
     * @see https://thumbor.readthedocs.io/en/latest/rgb.html
     * @param {( string | { r: number | string, g: number | string, b: number | string } )} rgb - Color channels values
     * @example { r: 255, g: 255 b: 255 } - object style
     * @example "255,255,255" - string style
     * @returns { string }
     */
    rgb: (rgb) => {
      if (typeof rgb === "object") {
        return `/filters:rgb(${rgb.r},${rgb.g},${rgb.b})`;
      }

      return `/filters:rgb(${rgb})`;
    },

    /**
     * @description Rotates the image according to the angle value passed.
     * @see https://thumbor.readthedocs.io/en/latest/rotate.html
     * @param {string | number} angle - Color channels values
     * @returns { string }
     */
    rotate: (angle) => `/filters:rotate(${angle})`,

    /**
     * @description Enhances apparent sharpness of the image.
     * @see https://thumbor.readthedocs.io/en/latest/sharpen.html
     * @param { object } params - Color channels values
     * @param { string | number } amount - Sharpen amount. Typical values are between 0.0 and 10.0.
     * @param { string | number } radius - Sharpen radius. Typical values are between 0.0 and 2.0.
     * @param { string | number } [luminanceOnly] - Sharpen only luminance channel.
     * @returns { string }
     */
    sharpen: (value) => {
      if (typeof value === "object") {
        return `/filters:sharpen(${value.amount},${value.radius},${value.luminanceOnly || false})`;
      }

      return "";
    },

    /**
     * @description Stretches the image until it fits the required width and height, instead of cropping the image.
     * @see https://thumbor.readthedocs.io/en/latest/stretch.html
     * @returns { string }
     */
    stretch: () => `/filters:stretch()`,

    /**
     * @description Remove any EXIF (metadata) information in the image.
     * @see https://thumbor.readthedocs.io/en/latest/strip_exif.html
     * @see https://en.wikipedia.org/wiki/Exif
     * @returns { string }
     */
    stripEXIF: () => `/filters:strip_exif()`,

    /**
     * @description Remove any ICC information in the image.
     * @see https://thumbor.readthedocs.io/en/latest/strip_icc.html
     * @see https://en.wikipedia.org/wiki/ICC_profile
     * @returns { string }
     */
    stripICC: () => `/filters:strip_icc()`,

    /**
     * @description Adds a watermark to the image.
     * @see https://thumbor.readthedocs.io/en/latest/watermark.html
     * @see https://docs.aws.amazon.com/solutions/latest/serverless-image-handler/thumbor-filters.html
     * @param { object } params
     * @param { string } bucket - Source bucket of the watermark image
     * @param { string } key - Key of the watermark image in bucket
     * @param { string | number } x - Horizontal position that the watermark will be in. See more details in THUMBOR docs
     * @param { string | number } y - Vertical position that the watermark will be in. See more details in THUMBOR docs
     * @param { string | number } [alpha] - Watermark image transparency (0-100)
     * @param { string | number } [wRatio] - Percentage of the width of the image the watermark should fit-in. See more details in THUMBOR docs
     * @param { string | number } [hRatio] - Percentage of the height of the image the watermark should fit-in. See more details in THUMBOR docs
     * @returns { string }
     */
    watermark: (params) => {
      if (typeof params !== "object") return "";

      if (
        !params.bucket ||
        !params.key ||
        (typeof params.x !== "number" && !params.x) ||
        (typeof params.y !== "number" && !params.y)
      ) {
        return "";
      }

      let filter = `/filters:watermark(${params.bucket},${params.key},${params.x},${params.y}`;

      if (typeof params.alpha !== "number" && !params.alpha) {
        return `${filter})`;
      }

      filter = `${filter},${params.alpha}`;

      if (typeof params.wRatio !== "number" && !params.wRatio) {
        return `${filter})`;
      }

      filter = `${filter},${params.wRatio}`;

      if (typeof params.hRatio !== "number" && !params.hRatio) {
        return `${filter})`;
      }

      filter = `${filter},${params.hRatio}`;

      return `${filter})`;
    },
  };

  /* 
    Apply filters to image path
    Ignores falsy modifier value if this is not a number type.
  */
  if (modifiers && /* TODO: remove after solving gif problem */ !src.endsWith(".gif")) {
    for (const [key, value] of Object.entries(modifiers).reverse()) {
      if (resolveImageModifier[key] && (value || typeof value === "number")) {
        path = `${resolveImageModifier[key](value) || ""}${path}`;
      }
    }
  }

  // Apply format filter to gif images. Because handler returns all images in webp format by default
  if (src.endsWith(".gif")) {
    path = `${resolveImageModifier.toFormat("gif")}${path}`;
  }

  /* 
    Add bucket alias as first part of path if alias is provided.
    Handler uses "images" bucket by default.
   */
  if (modifiers.bucketAlias && modifiers.bucketAlias !== "default") {
    path = `/${modifiers.bucketAlias}${path}`;
  }

  return {
    url: `${domain}${path}`,
  };
}

/**
 * @typedef {{
 *  autoJpg?: boolean,
 *  bgColor?: string,
 *  blur?: string | number,
 *  fillColor?: string,
 *  convolution?: string,
 *  resize?: object | string,
 *  equalize?: boolean,
 *  grayscale?: boolean,
 *  toFormat?: ("gif" | "heic" | "heif" | "jpeg" | "png" | "raw" | "tiff" | "webp")
 *  upscale?: boolean,
 *  noUpscale?: boolean,
 *  proportion?: string | number,
 *  qualityLevel?: string | number,
 *  resize?: ({ width: number | string } | string),
 *  crop?: ({ width: number | string } | string),
 *  rgb?: ({ r: string | number, b: string | number, g: string | number } | string),
 *  rotate?: string | number,
 *  sharpen?: ({ amount: string | number, radius: string | number, luminanceOnly?: boolean })
 *  stretch?: boolean,
 *  stripEXIF?: boolean,
 *  stripICC?: boolean,
 *  watermark?: { bucket: string, key: string, x: string | number, y: string | number, alpha?: string | number, wRatio?: string | number, hRatio?: string | number }
 *  bucketAlias?: string,
 *  [x: string]?: any
 * }} AvailableModifiers
 */
